import authHeader from './authHeader';


class CustomerService {
    getCustomer(id) {
        return authHeader.apiUrl()
            .get("/thirdparties/" + id)
    }
    createCustomer(data) {
        return authHeader.apiUrl()
            .post("/thirdparties/", data)
    }

}

export default new CustomerService();
