import authHeader from './authHeader';


class UploadService {
    postSimulationFile(idSimulation, file, base64file) {
        return authHeader.apiUrl()
            .post("/financement/simulation/"+idSimulation+"/uploadDocument/", {
                "fileName": file.name,
                "fileContent": base64file,
                "fileEncoding": "base64",
                "overwriteIfExists": true
            })
    }
    deleteSimulationFile(idSimulation,file) {
        return authHeader.apiUrl()
            .delete("/financement/simulation/"+idSimulation+"/deleteDocument/", {
                params: {
                    fileName: file,
                }
            })
    }

    getSimulationFile(idSimulation) {
        return authHeader.apiUrl()
            .get("/financement/documents", {
                params: {
                    elementname: 'fin_simulation',
                    id: idSimulation,
                    documentType: 'api'
                }
            })
    }

    getSimulationEtude(idSimulation) {
        return authHeader.apiUrl()
            .get("/financement/documents", {
                params: {
                    elementname: 'fin_simulation',
                    id: idSimulation,
                    documentType: 'etude'
                }
            })
    }

    getContratFile(idSimulation) {
        return authHeader.apiUrl()
            .get("/financement/documents", {
                params: {
                    elementname: 'fin_simulation',
                    id: idSimulation,
                    documentType: 'contrat'
                }
            })
    }

    getPvFile(idSimulation) {
        return authHeader.apiUrl()
            .get("/financement/documents", {
                params: {
                    elementname: 'fin_simulation',
                    id: idSimulation,
                    documentType: 'pv'
                }
            })
    }

    downloadSimulationFile(idSimulation, filename) {
        return authHeader.apiUrl()
            .get("/financement/documents/download", {
            params: {
                elementname: 'fin_simulation',
                id: idSimulation,
                filename: filename,
            }
        })
    }
    generateDocument(idSimulation,docName){
        return authHeader.apiUrl()
            .put("/financement/document/builddoc", {
                element_type: 'fin_simulation',
                element_id: idSimulation,
                doctemplate: docName,
            })
    }

    // eslint-disable-next-line no-unused-vars
    changeSimulationFile(idSimulation,idFile,base64file) {
        return authHeader.apiUrl()
            .put("/financement/compliances/" + idSimulation + "/documents/" + idFile, {
                "fileName": "string",
                "documentCategory": "test",
                "fileContent": base64file,
                "fileEncoding": "base64",
                "overwriteIfExists": true

            })
    }
}

export default new UploadService();
