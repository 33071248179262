import authHeader from './authHeader';

class ContractService {
    getContracts(siret) {
        return authHeader.apiUrl()
            .get("/financement/contract", {
                params: {
                    idprof2: siret,
                    ongoing: 1
                }
            })
    }
}

export default new ContractService();