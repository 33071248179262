<template>
  <div class="progressbar">
    <div :style="barStyle()" class="progressbar_bar">
      <slot/>
    </div>
  </div>
</template>

<script setup>

import {defineProps, ref,watch} from "vue";

const props = defineProps({
  value: {
    type: Number,
    default: 0
  },
  origin: {
    type: String,
    default: 'left'
  },
  scale: {
    type: String,
    default: 'X',
    validator: v => ['X', 'Y'].includes(v)
  }
});

let value  = ref(props.value);
let origin = ref(props.origin);
let scale  = ref(props.scale);
let ready  = ref(false)

setTimeout(() => { ready.value = true }, 0)

watch( () => props.value, (newId) => {
  value.value = newId;
});

function barStyle(){
  if (!ready.value) {
    return {
      transform: `scale${scale.value}(0)`
    }
  }
  return {
    transform: `scale${scale.value}(${value.value * 0.01})`,
    transformOrigin: `${origin.value}`
  }
}

</script>

<style scoped>
.progressbar {
  height: 0.20rem;
  background: transparent;
  position: absolute;
  left:0;
  top:0;
  right:0;
}

.progressbar_bar {
   height: 100% ;
  background: linear-gradient(to right,#9569ab,#ab88bc);
   transition: transform 6s ease;
}
</style>
