<template>
  <div class="d-flex align-items-center flex-prop">
    <p class="gris-7 mb-0 t-1e"><fa :icon="logo" class="fa-lg float-left mr-3"/>{{ name }}</p>
    <p class="t-1-5e mb-0"><strong>{{
        vueNumberFormat(parseFloat(price), {
          thousand: ' ',
          precision: precision,
          'suffix': ' ',
        })
      }}</strong>
      <fa :icon="icon" class="fa-sm"></fa>
    </p>
  </div>
</template>
<script>
export default {
  name: "FundingPrice",
  props: {
    name: { required: true },
    price: { required: true },
    icon: {
      default: 'euro-sign'
    },
    logo: {
      default: 'fa-university'
    },
    precision: {
      default: 2
    }
  }
}
</script>
<style>
  .flex-prop{
    justify-content: space-between;
    align-items: center;
    background-color: #eeeeee;
    padding: 3px 10px 3px 10px;
    border: 1px solid lightgrey;
  }
</style>