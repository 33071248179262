<template>
  <div>
    <div class=" box-shadow p-3">
      <div class="d-flex contractInfo align-items-center text-muted mb-3">
        <span class="badge badge-info-light text-12 text-uppercase mr-2">{{
            contract.reference || 'Aucune référence'
          }}</span>
        <span class="text-uppercase ml-2 mr-2">{{ partner }}</span>
      </div>
      <div class="row no-gutters">
        <div class="col-4">
          <div class="tag">
            <Tag :text="fileStatus" v-if="fileStatus"></Tag>
            <Tag text="Incident de paiement" v-if="contract.incident_paiement !== 'NON'"></Tag>
          </div>
          <div class="figureContract">
            <div class="d-flex flex-column justify-content-around text-muted">
              <p>Loyer HT:</p>
              <p>Durée:</p>
              <p>Terme:</p>
              <p>Ass:</p>
              <p>Maint:</p>
            </div>
            <div class="d-flex flex-column justify-content-around font-weight-bold ft1em">
              <span>{{ vueNumberFormat(contract.echeance) }}</span>
              <span>{{ contractFunding.duree }} {{ contractPeriodicity[contractFunding.periodicite] }}</span>
              <span>{{ getTerme(contractFunding.terme) }}</span>
              <span>{{ vueNumberFormat(contractFunding.assurance) }}</span>
              <span>{{ vueNumberFormat(contractFunding.montant_prestation) }}</span>
            </div>
          </div>
          <div class="date text-muted mt-3">
            <span>{{ getDate(contractFunding.date_debut) }}</span>
            <span><fa icon="arrow-right" class="mr-1 ml-1"></fa></span>
            <span>{{ getDate(contractFunding.date_fin) }}</span>
          </div>
        </div>
        <div class="col-8 row no-gutters justify-content-around">
          <div class="col-5 deadline relative p-1 text-muted">
            <span class="absolute label">Prochaine Echéance</span>
            <div class="d-flex justify-content-center align-items-center flex-grow-1 mt-3">
              <div class="t-30 pl-2">
                <fa icon="calendar-alt"></fa>
              </div>
              <div class="flex-grow-1 text-center">
                <span class="font-weight-bold ft1em">{{ getDate(contractFunding.date_prochaine_echeance) }}</span>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <span>{{ contractFunding.duree_passe }}/{{ contractFunding.duree }}</span>
            </div>
          </div>
          <div class="col-6 paymentPeriod relative p-1 text-muted">
            <span class=" absolute label">Solde renouvelant après paiement du :</span>
            <div class="d-flex justify-content-around align-items-center flex-grow-1 mt-2" v-if="contract.watchPeriod">
              <div class="d-flex flex-column align-items-center">
                <span>{{ getDate(contract.balanceAfterPayment[0].date_start) }}</span>
                <span>au</span>
                <span>{{ getDate(contract.balanceAfterPayment[0].date_end) }}</span>
                <span class="font-weight-bold text-1em pt-1 paymentFormat">{{
                    vueNumberFormat(contract.balanceAfterPayment[0].payment)
                  }}</span>
              </div>
              <div>
                <fa icon="undo" class="t-30 text-muted" size="2xl"></fa>
              </div>
              <div class="d-flex flex-column align-items-center text-muted">
                <span>{{ getDate(contract.balanceAfterPayment[1].date_start) }}</span>
                <span>au</span>
                <span>{{ getDate(contract.balanceAfterPayment[1].date_end) }}</span>
                <span class="font-weight-bold pt-1 paymentFormat text-1em">{{
                    vueNumberFormat(contract.balanceAfterPayment[1].payment)
                  }}</span>
              </div>
            </div>
            <div class="d-flex justify-content-around align-items-center flex-grow-1" v-else>
              <p class="text-center error">Soldes non disponibles <br>Contactez le service de financement</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { periodicityVerboseArray, dateFormat } from "../helpers/tools";
import Tag from './Tag'

export default {
  name: 'ContractCard',
  components: { Tag },
  props: {
      contract: {
        required: true
      },
      partner: {},
  },
  data() {
    return {
      contractPeriodicity: null,
      contractFunding: null,
      fileStatus: null,
    }

  },
  methods: {
    getTerme(terme){
      if(terme === 1){
        return 'A échoir'
      }
      return 'Echu'
    },
    getDate(date) {
      return dateFormat(date);
    }
  },
  beforeMount() {
    this.contractPeriodicity = periodicityVerboseArray();
    this.contractFunding = this.contract.financement ?? this.contract.financementLeaser;
    this.fileStatus = this.contract.TStatutDossier[parseInt(this.contract.fk_statut_dossier)] ?? null;
  }
}

</script>

<style scoped>
span, p{
  font-size: 13px;
  margin-bottom: 0;
}
.containerContrat {
  display: grid;
  grid-template-columns:1fr 0.8fr 0.3fr 0.4fr 0.8fr;
  grid-template-areas:
            "contractInfo contractInfo tag tag tag"
            "figureContract deadline deadline paymentPeriod paymentPeriod"
            "date empty empty empty modification";
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  padding: 5px;
}

.contractInfo {
  grid-area: contractInfo;
}

.figureContract {
  grid-area: figureContract;
  display: grid;
  grid-template-columns: 0.4fr 0.7fr;
}

.date {
  grid-area: date;
}

.deadline {
  grid-area: deadline;
  border: 1px solid #E2E2E2;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.paymentPeriod {
  grid-area: paymentPeriod;
  border: 1px solid #E2E2E2;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.tag {
  grid-area: tag;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.label {
  top: -8px;
  z-index: 999;
  background: #ffff;
  padding: 0 5px;
  left: 5px;
}

.ft12em {
  font-size: 1.2em;
}

.color-blue-dark {
  color: #0c517c !important;
}

.error {
  color: #E03D38
}

.paymentFormat {
  background: #6e398e;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 5px;
}
</style>