import authHeader from './authHeader';
import { reverseDateFormat } from '@/helpers/tools';

class FundingService {
    getFunding(id) {
        return authHeader.apiUrl().get('/financement/simulation/' + id);
    }

    getFundingStats() {
        return authHeader.apiUrl().get('/financement/simulation/stats');
    }

    /* eslint-disable */
    getLastSemesterFundingStats() {
        const today = new Date();
        const firstDay = reverseDateFormat(new Date(today.getFullYear(), today.getMonth() - 3, 1)).replaceAll('/', '');
        const LastDay = reverseDateFormat(new Date(today.getFullYear(), today.getMonth(), 0)).replaceAll('/', '');

        return authHeader.apiUrl().get('/financement/simulation/stats', {
            params: {
                sqlfilters: 'AND simu.date_cre >= ' + firstDay + ' AND simu.date_cre < ' + LastDay
            }
        });
    }

    postSimulationStatusModification(idFunding) {
        return authHeader.apiUrl().put(`/financement/simulation/${idFunding}/setModif`)
    }

    postSimulation(funding, save = true) {
        const financementCreateSimulationModel = JSON.parse(JSON.stringify(funding));
        if (save && funding.id > 0) {
            return authHeader.apiUrl().put('/financement/simulation/' + funding.id, financementCreateSimulationModel);
        }
        return authHeader.apiUrl().post('/financement/simulation/', financementCreateSimulationModel);
    }

    postContact(idfunding, idContact) {
        return authHeader.apiUrl().post('/financement/simulation/' + idfunding + '/contact/external/' + idContact + '/CUSTOMERSIGN');
    }

    deleteContact(idfunding, idContact) {
        return authHeader.apiUrl().delete('/financement/simulation/' + idfunding + '/contact/' + idContact);
    }

    scoreSimulation(funding, siret) {
        if (siret) {
            const financementScoreSimulationModel = JSON.parse(JSON.stringify(funding));
            financementScoreSimulationModel.siret = siret;
            return authHeader.apiUrl().post('/financement/simulation/scoring', financementScoreSimulationModel);
        }
        return false;
    }

    getFundings() {
        return authHeader.apiUrl().get('/financement/simulation', {
            params: {
                limit: 1000
            }
        });
    }

    getPayments(id) {
        return authHeader.apiUrl().get('/financement/payments', {
            params: {
                id: id
            }
        });
    }

    getSimulationsByThirparties(thirdPartyId, limit, page = 0, filters = '') {
        let thirdparty = `(t.fk_soc:=:${thirdPartyId})`;
        if (filters) {
            thirdparty += ` and ${filters}`;
        }

        return authHeader.apiUrl().get('/financement/simulation', {
            params: {
                limit: limit,
                page: page,
                pagination_data: true,
                sqlfilters: thirdparty
            }
        });
    }
}

export default new FundingService();
