const filter = {

    contract: {
        type: 'select',
        name: 'selectContratType',
        options: ['Tous les types de contrat'],
        value: 'Tous les types de contrat'
    },
    leaser: {
        type: 'select',
        name: 'selectLeaser',
        options: ['Tous les leasers'],
        value: 'Tous les leasers'
    },
    rangeMax: {
        type: 'range',
        name: 'Loyer max',
        value: 0,
        maxRange: 0,
        minRange: 0,
    },
    rangeMin: {
        type: 'range',
        name: 'Loyer min',
        value: 0,
        maxRange: 0,
    },
    get contractValue() {
        return this.contract.value
    },
    set contractValue(data) {
        this.contract.value = data
    },
    get contractOptions() {
        return this.contract.options
    },
    set contractOptions(data) {
        if (!this.contract.options.includes(data)) {
            this.contract.options.push(data)
        }
    },
    get leaserValue() {
        return this.leaser.value
    },
    set leaserValue(data) {
        this.leaser.value = data
    },
    get leaserOptions() {
        return this.leaser.options
    },
    set leaserOptions(data) {
        if (!this.leaser.options.includes(data)) {
            this.leaser.options.push(data)
        }
    },
    get minRangeValue() {
        return this.rangeMin.value
    },
    get maxRangeValue() {
        return this.rangeMax.value
    },
    set minRangeValue(data) {
        this.rangeMin.value = data
    },
    set maxRangeValue(data) {
        this.rangeMax.value = data
    },
    get maxRange() {
        return this.rangeMax.maxRange
    },
    set maxRange(data) {
        this.rangeMax.maxRange = data
    },

}

export default filter





