<template>
  <div class="col txt-bold">
      <file-pond
          name="test"
          ref="pond"
          allow-remove="false"
          label-idle="Glissez et déposez vos fichiers..."
          v-bind:allow-multiple="true"
          v-bind:files="this.files"
          v-on:addfile="showModalDocument"
      />
    <Transition name="modal">
      <div v-if="showModal" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <span><fa icon="question-circle" class="fa-2xl"></fa><h3 class="ml-3">Type de document</h3></span>
            </div>
            <div class="modal-body">
              <p>Séléctionner le type de piece</p>
              <select required v-model="documentType" class="form-control">
                <option v-for="type in documents" v-bind:key="type" :value="type">{{ this.$store.getters.getLabelDocuments[type] }}</option>
                <option value="other">Autres</option>
              </select>
            </div>
            <div class="modal-footer">
              <button class="btn btn-purple" v-on:click="cancelUpload">Annuler</button>
              <button class="btn btn-purple" v-on:click="addNewFile">Confirmer</button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>

import vueFilePond from "vue-filepond";
import compliance from "../services/compliance.service";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css";
import FilePondPluginGetFile from 'filepond-plugin-get-file';


const FilePond = vueFilePond(
    FilePondPluginGetFile,
);

export default {
  components: {
    FilePond,
  },
  name: 'FileManagerCompliance',
  props: ['compliance', 'documents'],
  data() {
    return {
      files: [],
      idSimulation: this.$route.params.idFunding,
      showModal: false,
      documentType: null,
      currentFile: null,
    }
  },
  methods: {
    async getFilesFromApi(){
      await compliance.getComplianceFile(this.compliance.rowid).then(async(response) => {
        this.sentDocument(response.data);
        for (let i = 0; i < response.data.length; i++) {
          let file = response.data[i];
          await compliance.downloadComplianceFile(this.compliance.rowid, file.name).then(async(response) => {
            const sliceSize = 512;
            const byteCharacters = atob(response.data.content);
            const byteArrays = [];
            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }
            let blob = new Blob(byteArrays, {type: response.data.contentType});
            let finalFile = new File([blob], file.name, {type: response.data['content-type']});
            finalFile.baseInitFilePond = true;
            await this.$refs.pond.addFile(finalFile);
          }).catch(() => {
            this.$notify({
              duration: 3000,
              text: 'Erreur lors de la récuperation d\'un document',
              type: 'error'
            });
          })
        }
      }).catch((error) => {
        if(error.response.status === 404){
          return;
        }
        this.$notify({
          duration: 3000,
          text: 'Erreur lors de la récuperation de la liste des documents',
          type: 'error'
        });
      })
    },
    resetFileManager(){
      this.showModal = false;
      this.currentFile = null;
      this.documentType =  null;
    },
    cancelUpload(){
      this.$refs.pond.removeFile(this.currentFile)
      this.resetFileManager()
    },
    showModalDocument(error, file){
      if(!file.file.baseInitFilePond){
        this.currentFile = file;
        this.showModal = true;
      }
    },
    sentDocument(documents){
      this.$emit('documentSent', documents.map(doc => doc.keywords));
    },
    addNewFile(){
      if(this.documentType === null){
        this.$notify({
          duration: 3000,
          text: 'Le type de document doit être saisi',
          type: 'warn'
        });
        return
      }
      this.processBase64(this.currentFile.file);
    },
    sendFile(file,base64file){
      const base64String = base64file
          .replace('data:', '')
          .replace(/^.+,/, '');
      compliance.postComplianceFile(this.compliance.rowid, file, base64String, this.documentType).then(() => {
          this.$emit('documentNewSent', this.documentType);
          this.resetFileManager();
          this.$notify({
            duration: 3000,
            text: 'Le document a été envoyé',
            type: 'success'
          });
        }).catch(() => {
          this.$notify({
            duration: 3000,
            text: 'Erreur lors de l\'envoi du document',
            type: 'error'
          });
          this.$refs.pond.removeFile(file)
      });

    },
    processBase64(file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        await this.sendFile(file, reader.result)
      };
      reader.onerror = () => {
        this.$notify({
          duration: 3000,
          text: 'Erreur lors de conversion en base 64',
          type: 'error'
        });
        return false;
      };
    },
  },
  mounted() {
    if(this.idSimulation && ![8,5].includes(this.compliance.status)){
      this.getFilesFromApi();
    }
  }
}
</script>

<style scoped>

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 30%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header span{
  display: flex;
}

.modal-header h3 {
  margin-top: 0;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
