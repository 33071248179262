
class Funding {
    validStatus = ['OK','OK_RG','OK_FIN'];
    validStatusDatatable = ['OK','OK_RG','OK_FIN','OK_EXP'];
    status = {
        DRAFT: {
            id: -1,
            text: 'Brouillon',
            ifa: 'stopwatch',
            icon: 'ellipsis-h',
            color: '',
            colorhex: '#FFFFFF',
            editable: false
        },
        WAIT: {
            id: 1,
            text: 'En étude',
            ifa: 'stopwatch',
            icon: 'ellipsis-h',
            color: '',
            colorhex: '#6f42c1',
            editable: false
        },
        WAIT_LEASER: {
            id: 2,
            text: 'Attente d\'éléments',
            ifa: 'question-circle',
            icon: 'ellipsis-h',
            color: '',
            colorhex: '#6f42c1',
            editable: false
        },
        WAIT_SELLER: {
            id: 2,
            text: 'Attente d\'éléments',
            ifa: 'question-circle',
            icon: 'ellipsis-h',
            color: '',
            colorhex: '#EE7202',
            editable: false
        },
        WAIT_MODIF: {
            id: 6,
            text: 'En étude',
            color: '',
            icon: 'ellipsis-h',
            colorhex: '#6f42c1',
            editable: false
        },
        WAIT_AP: {
            id: 3,
            text: 'Accord principe',
            ifa: 'handshake',
            icon: 'check',
            color: 'success',
            colorhex: '#6f42c1',
            editable: false
        },
        OK: {
            id: 4,
            text: 'Accord',
            ifa: 'thumbs-up',
            icon: 'check',
            color: 'success',
            colorhex: '#23A638',
            editable: true,

        },
        OK_RG: {
            id: 7,
            text: 'Accord',
            ifa: 'thumbs-up',
            icon: 'check',
            color: 'success',
            colorhex: '#23A638',
            editable: true,
        },
        OK_FIN: {
            id: 8,
            text: 'Terminé',
            ifa: 'thumbs-up',
            icon: 'check',
            color: 'success',
            colorhex: '#23A638',
            editable: false,
        },
        OK_EXP: {
            id: 9,
            text: 'Expirée',
            ifa: 'ban',
            icon: 'times',
            color: 'ss',
            colorhex: '#808080',
            editable: false,
        },
        KO: {
            id: 5,
            text: 'Refus',
            ifa: 'ban',
            icon: 'times',
            color: 'danger',
            colorhex: '#EA4B58',
            editable: false,
        },
        SS: {
            id: 6,
            text: 'Sans suite',
            icon: 'times',
            color: 'ss',
            colorhex: '#808080',
            editable: true
        }
    }
}
export default new Funding
