import {createStore} from "vuex";
import createPersistedState from "vuex-persistedstate";
import daily_messages from "@/helpers/daily_message.json";

export const store = createStore({
    state() {
        return {
            user: null,
            token: null,
            date: null,
            contractTypes: null,
            labelDocuments: [],
            message: null,
            dateEdit: null,
            usedMessage: []
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setToken(state, token) {
            state.token = token;
        },
        setDate(state, date) {
            state.date = date;
        },
        setContractTypes(state, contractTypes) {
            state.contractTypes = contractTypes;
        },
        setLabelDocuments(state, labelDocuments) {
            state.labelDocuments = labelDocuments;
        },
        setDateEdit (state, date) {
            state.dateEdit = date;
        },
        setMessage (state, newMessage) {
            state.message = newMessage;
        },
        setUsedMessage(state, message) {
            state.usedMessage.push(message);
        },
        resetUsedMessage(state) {
            state.usedMessage = []
        }
    },
    getters: {
        getUser(state) {
            return state.user;
        },
        getDate(state) {
            return state.date;
        },
        getToken(state) {
            return state.token;
        },
        getContractTypes(state) {
            return state.contractTypes;
        },
        getLabelDocuments(state) {
            return state.labelDocuments;
        },
        getMessage(state) {
            if (!state.dateEdit || (parseInt(new Date().getTime()/1000) - parseInt(new Date(state.dateEdit).getTime()/1000))/3600 > 23) {
                let message =  daily_messages.messages[Math.floor(Math.random() * daily_messages.messages.length)];

                if (daily_messages.messages.length === state.usedMessage.length) {
                    store.commit('resetUsedMessage')
                } else {
                    store.commit('setUsedMessage', state.message)
                    while (state.usedMessage.includes(message)) {
                        message =  daily_messages.messages[Math.floor(Math.random() * daily_messages.messages.length)];
                    }
                }
                store.commit('setDateEdit', (new Date()))
                store.commit('setMessage', message)
            }

            return state.message;
        },
        getAvailableMessage(state) {
            return state.usedMessage;
        }
    },
    plugins: [createPersistedState()]
})

