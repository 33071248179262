<template>
  <div v-if="show">
    <div class="header d-flex">
      <div class="stepper" v-for="step in steps" v-bind:key="step.id">
        <button
          class="btn btn-stepper"
          v-bind:class="{ active: step.id === this.currentStep, noright: step.id === this.steps.length, noleft: step.id === 1, finish: step.finish }"
          :disabled="!(step.editable || step.finish)"
        >
          <template v-if="step.finish">
            <fa icon="check" class="fa-xl"></fa>
          </template>
          <template v-else>
            <fa icon="stopwatch" class="fa-xl"></fa>
          </template>
        </button>
        <p v-bind:class="{ active: step.id === this.currentStep }">{{ step.title }}</p>
      </div>
    </div>
    <div class="content-stepper">
      <section v-if="currentStep === 1">
        <div class="step-header">
          <h4>Etape 1 : Informations du client</h4>
        </div>
        <div class="container">
          <div class="row border">
            <div class="col-md-8 m-5">
              <div class="d-flex justify-content-between mb-2">
                <h5>Choisir un contact pour la génération du contrat</h5>
                <template v-if="!contacts.length">
                  <p><i>Veuillez créer un contact associé à la fiche pour poursuivre.</i></p>
                </template>
                <div>
                  <button class="btn purple-button btn-edit m-2" id="show-modal" @click="modalContact(contact)"><fa icon="plus"></fa></button>
                  <button class="btn purple-button btn-edit m-2" id="show-modal" @click="modalContact(this.simulation.front_infos.contacts[0].id)">
                    <fa icon="pencil"></fa>
                  </button>
                </div>
              </div>
              <select :disabled="!contacts.length" v-model="contratContact" @change="addContact" class="form-control col-12">
                <option selected v-bind:key="contact.id" :value="contact.id" v-for="contact in contacts">
                  {{ contact.firstname + ' ' + contact.lastname + ' ( ' + contact.poste + ' )' }}
                </option>
              </select>
            </div>
            <div class="valdiator col-md-2">
              <fa v-if="this.simulation.front_infos.contacts[0]?.rowid" class="fa-2xl green" icon="check"></fa>
              <fa v-else class="fa-2xl red" icon="xmark"></fa>
            </div>
          </div>
          <div class="row mt-5 border">
            <div class="col-md-8 m-5">
              <div class="d-flex justify-content-between">
                <h5>Liste des matériels</h5>
                <button class="btn purple-button btn-edit m-2" id="show-modal" @click="modalMateriel()"><fa icon="plus"></fa> Ajouter</button>
              </div>
              <table v-if="materiels.length" class="table mt-2 table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">Dénomination</th>
                    <th scope="col">Quantité</th>
                    <th scope="col">Etat</th>
                    <th scope="col">Edition</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-bind:key="materiel.id" v-for="materiel in materiels">
                    <th scope="row">{{ materiel.label }}</th>
                    <td>{{ materiel.qty }}</td>
                    <td>{{ materiel.condition }}</td>
                    <td class="text-center">
                      <button class="el-button is-circle btn-edit m-auto" id="show-modal" @click="modalMateriel(materiel)">
                        <fa icon="pencil"></fa>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <template v-else>
                <div class="info-center d-flex mt-5">
                  <h6 class="font-italic info-center">Aucun matériel</h6>
                </div>
              </template>
            </div>
            <div class="valdiator col-md-2">
              <fa v-if="materiels.length" class="fa-2xl green" icon="check"></fa>
              <fa v-else class="fa-2xl red" icon="xmark"></fa>
            </div>
          </div>
        </div>
        <Teleport to="body">
          <Materiel
            :show="showModal"
            :products="products"
            :materielParent="currentMateriel"
            @close="
              showModal = false;
              currentMateriel = null;
            "
          ></Materiel>
        </Teleport>
        <Teleport to="body">
          <Contact
            :show="showModalContact"
            :contactBasic="currentContact"
            @close="
              showModalContact = false;
              currentContact = null;
            "
          ></Contact>
        </Teleport>
      </section>
      <section v-if="currentStep === 2">
        <div class="step-header d-flex justify-content-between">
          <h4>Etape 2 : Signature du contrat</h4>
        </div>
        <div class="row text-center mb-3">
          <div class="col-5 min-vh-55 bg-grey-sign m-r-auto border b-signature">
            <div class="height-40">
              <h3 class="m-5 svg-signature">Signer le contrat manuellement</h3>
              <p class="m-3 font-italic">
                Télécharger le contrat ci-dessus. Le document doit être paraphé et signé en 3 exemplaires puis déposé lors de la conformité à l'étape
                suivante.
              </p>
            </div>
            <div class="height-40">
              <div class="icon-large-fa">
                <fa class="fa-xl svg-signature" icon="print"></fa>
              </div>
              <button class="btn btn-purple" v-on:click="this.showModalConfirm = true">Passer cette étape et signer manuellement</button>
            </div>
          </div>
          <div class="border"></div>
          <div class="col-5 min-vh-55 bg-grey-sign m-l-auto border b-signature">
            <template v-if="[-1,0,6].includes(this.simulation.contract_status)">
              <div class="height-40">
                <h3 class="m-5 svg-signature">Lancer la signature électronique</h3>
                <p class="m-3 font-italic">
                  Lancement de la signature éléctronique via Ededoc. Une fois le contrat signé, l'accés à la conformité sera disponible.
                </p>
              </div>
              <div class="height-40">
                <div class="icon-large-fa">
                  <fa class="fa-xl svg-signature" icon="bolt"></fa>
                </div>
                <button v-if="!this.loader.contrat" class="btn btn-purple" v-on:click="startEdedocSign()">Démarrer la signature éléctronique</button>
                <div v-else class="m-auto">
                  <div class="spinner-border purple-loader" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="height-30">
                <h3 class="m-5 svg-signature">Statut de la signature</h3>
                <p class="m-3 font-italic">Une fois que les deux signataires ont signés le contrat, vous pouvez passer à l'étapes suivante.</p>
              </div>
              <div class="text-center m-auto">
                <p :class="clientClass" class="btn mr-4 ml-4 mb-4 btn-none">Client</p>
                <p :class="koesioClass" class="btn mr-4 ml-4 mb-4 btn-none">Koesio</p>
              </div>
              <div class="progress-div">
                <div class="m-2" v-if="this.simulation.contract_status === 5">
                  <fa icon="road-barrier" class="fa-2xl red"></fa>
                  <h5 class="text bg-white m-3 p-2">Signature du contrat refusé</h5>
                </div>
                <div
                  role="progressbar"
                  v-else
                  :aria-valuenow="this.signStyle.value"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :style="{
                    '--value': this.signStyle.value,
                    '--primary': this.signStyle.primary,
                    '--secondary': this.signStyle.secondary,
                    '--text': this.signStyle.text
                  }"
                ></div>
              </div>
              <template v-if="this.simulation.contract_status !== 5">
              <button v-if="!this.loader.cancel" class="btn m-4 btn-purple" v-on:click="this.showModalConfirmCancel = true">
                Annuler la signature éléctronique
              </button>
              <div v-else class="m-auto">
                <div class="spinner-border purple-loader" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              </template>
            </template>
          </div>
          <div class="embed-responsive embed-responsive-16by9 mt-5 mb-3">
            <iframe class="embed-responsive-item" :src="contratDownload"></iframe>
          </div>
        </div>
      </section>
      <Teleport to="body">
        <Confirm
          :show="showModalConfirm"
          title="Confirmer votre choix"
          message="Veuiller à bien avoir <b>téléchargé le contrat</b>. Une fois cette étape validée, vous ne pouvez plus revenir en arrière"
          @success="nextStep()"
          @close="showModalConfirm = false"
        ></Confirm>
      </Teleport>
      <Teleport to="body">
        <Confirm
          :show="showModalConfirmCancel"
          title="Annuler la signature éléctronique"
          message="L'annulation de la signature peut prendre 10 à 15 min.Une fois annuler, vous pouvez changer les informations du contrat et envoyer à nouveau une demande de signature éléctronique."
          @success="cancelEdedocSign()"
          @close="showModalConfirmCancel = false"
        ></Confirm>
      </Teleport>
      <section v-if="currentStep === 3">
        <div class="step-header">
          <h4>Etape 3 : Dossier</h4>
          <div class="row">
            <div class="col-8">
              <h6 class="bg-white text-uppercase font-weight-bold p-2">Documents necessaire :</h6>
              <ul>
                <template v-bind:key="document[0]" v-for="document in documentRequired">
                  <li>
                    {{ this.$store.getters.getLabelDocuments[document]
                    }}<span v-if="documentSent.includes(document)"><fa icon="check-circle" class="ml-3 green" size="lg"></fa></span>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <FileManagerCompliance ref="fileManager" :compliance="this.compliance" :documents="currentDocumentRequired" @documentNewSent="addDocumentSent" @documentSent="setDocumentSent"></FileManagerCompliance>
          <div class="text-center m-auto statut row">
            <div class="col-5 bg-grey p-4">
              <div class="text-center row m-auto">
                <template v-if="(this.simulation.delivery_report_status === 0 || this.simulation.delivery_report_status === 6) && !pvManual">
                  <h6 class="text-uppercase font-weight-bold p-1 col-12">Signature du PV</h6>
                  <button v-if="!this.loader.pv" class="btn btn-purple-inverted margin-btn col-12" v-on:click="startPVSign()">
                    Démarrer la signature
                  </button>
                  <div v-else class="m-auto">
                    <div class="spinner-border purple-loader" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="col-12 m-auto row no-gutters">
                    <template
                      v-if="[1,2,3].includes(this.simulation.delivery_report_status)"
                    >
                      <div class="col-9">
                        <h6 class="text-uppercase font-weight-bold p-2">Statut de la signature du PV</h6>
                        <i class="font-weight-bold"> En attente de signature</i>
                      </div>
                      <div class="col-3 d-flex">
                        <fa icon="battery-half" class="fa-2xl purple center-d-flex"></fa>
                      </div>
                    </template>
                    <template v-if="this.simulation.delivery_report_status === -1">
                      <div class="col-9">
                        <h6 class="text-uppercase font-weight-bold p-2">Statut de la signature du PV</h6>
                        <i class="font-weight-bold"> Annulé par le client</i>
                      </div>
                      <div class="col-3 d-flex">
                        <fa icon="road-barrier" class="fa-2xl red center-d-flex"></fa>
                      </div>
                    </template>
                    <template v-if="this.simulation.delivery_report_status === 5">
                      <div class="col-9">
                        <h6 class="text-uppercase font-weight-bold p-2">Statut de la signature du PV</h6>
                        <i class="font-weight-bold"> Refuse en signature</i>
                      </div>
                      <div class="col-3 d-flex">
                        <fa icon="road-barrier" class="fa-2xl red center-d-flex"></fa>
                      </div>
                    </template>
                    <template v-if="this.simulation.delivery_report_status === 4 || pvManual">
                      <div class="col-9">
                        <h6 class="text-uppercase font-weight-bold p-2">Statut de la signature du PV</h6>
                        <i class="font-weight-bold"> Signé</i>
                      </div>
                      <div class="col-3 d-flex">
                        <fa icon="battery-full" class="fa-2xl green center-d-flex"></fa>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </div>
            <div class="col-1 d-flex">
              <fa icon="arrow-right" class="fa-2xl arrow-right"></fa>
            </div>
            <div class="col-5 bg-grey p-4">
              <div class="text-center row m-auto">
                <div class="col-12 m-auto row no-gutters">
                  <template v-if="this.compliance?.status === 6">
                    <div class="col-9">
                      <h6 class="text-uppercase font-weight-bold p-2">Statut du dossier</h6>
                      <i class="font-weight-bold"> Non conforme : {{ this.compliance.commentaire }}</i>
                    </div>
                    <div class="col-3 d-flex">
                      <fa icon="road-barrier" class="fa-2xl red center-d-flex"></fa>
                    </div>
                  </template>
                  <template v-else-if="this.compliance?.status === 5 || this.compliance?.status === 8">
                    <div class="col-9">
                      <h6 class="text-uppercase font-weight-bold p-2">Statut du dossier</h6>
                      <i class="font-weight-bold"> Conforme</i>
                    </div>
                    <div class="col-3 d-flex">
                      <fa icon="battery-full" class="fa-2xl green center-d-flex"></fa>
                    </div>
                  </template>
                  <template v-else-if="this.compliance?.status === 0">
                    <div class="col-9">
                      <h6 class="text-uppercase font-weight-bold p-2">Statut du dossier</h6>
                      <i class="font-weight-bold">A envoyer</i>
                      <p v-if="this.simulation.documents?.pv !== 'Completed'" class="font-italic small">(Disponible après la signature du PV)</p>
                    </div>
                    <div class="col-3 d-flex">
                      <fa icon="battery-quarter" class="fa-2xl purple center-d-flex"></fa>
                    </div>
                  </template>
                  <template v-else-if="this.compliance?.status < 5">
                    <div class="col-9">
                      <h6 class="text-uppercase font-weight-bold p-2">Statut du dossier</h6>
                      <i class="font-weight-bold"> Etude</i>
                    </div>
                    <div class="col-3 d-flex">
                      <fa icon="battery-half" class="fa-2xl purple center-d-flex"></fa>
                    </div>
                  </template>
                  <template v-else>
                    <div class="col-9">
                      <h6 class="text-uppercase font-weight-bold p-2">Statut de la conformité</h6>
                      <i class="font-weight-bold"> Inconnu</i>
                    </div>
                    <div class="col-3 d-flex">
                      <fa icon="battery" class="fa-2xl center-d-flex"></fa>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div v-if="contratDownload" class="embed-responsive embed-responsive-16by9 mt-5 mb-3">
              <iframe class="embed-responsive-item" :src="contratDownload"></iframe>
            </div>
          </div>
        </div>
        <Teleport to="body">
          <Confirm
            :show="showModalConfirmFinal"
            title="Confirmer la demande d'envoi des pièces"
            message="Merci de bien déposer toutes les pièces demandées."
            @success="sendConformiteN2()"
            @close="showModalConfirmFinal = false"
          ></Confirm>
        </Teleport>
      </section>
      <section v-if="currentStep === 4">
        <div class="text-center">
          <lottie-player
            :src="require('@/assets/rocket.json')"
            background="transparent"
            class="m-auto"
            speed="1"
            style="width: 400px; height: 400px"
            loop
            autoplay
          ></lottie-player>
          <h3>Dossier validé !</h3>
          <p class="lead">Vous pouvez retrouver votre contrat dans l'onglet contrat de financement</p>
          <div class="">
            <button type="button" style="margin: 10px" class="btn btn-purple-inverted" @click="download('Contrat')">Afficher le contrat</button>
            <button type="button" class="btn btn-purple-inverted mr-2" @click="download('PV')">Afficher le PV</button>
          </div>
          <div v-if="contratDownload" class="embed-responsive embed-responsive-16by9 mt-5 mb-3">
            <iframe class="embed-responsive-item" :src="contratDownload"></iframe>
          </div>
        </div>
      </section>
    </div>
    <div class="footer d-flex">
      <template v-if="this.steps[currentStep - 1].id !== this.steps.length">
        <template v-if="this.currentStep !== 1">
          <button class="btn btn-purple m-2" :disabled="checkStepBackward(this.currentStep)" @click="previousStep()">Précédent</button>
        </template>
      </template>
      <template v-if="this.steps[currentStep - 1].id === this.steps.length - 1">
        <template v-if="this.compliance?.status === 0 || this.compliance?.status === 6">
          <button
            class="btn btn-purple-inverted m-2"
            :disabled="!authorizeSendN2"
            v-on:click="this.showModalConfirmFinal = true"
          >
            Envoyer
          </button>
        </template>
      </template>
      <template v-else-if="this.steps[currentStep - 1].id === this.steps.length"> </template>
      <template v-else-if="currentStep === 1">
        <button class="btn btn-purple m-2" :disabled="checkStep(this.currentStep)" @click="nextStep()">Générer le contrat</button>
      </template>
      <template v-else>
        <button class="btn btn-purple m-2" :disabled="checkStep(this.currentStep)" @click="nextStep()">Suivant</button>
      </template>
    </div>
  </div>
</template>

<script>
import ContactService from '@/services/contact.service';
import fundingService from '@/services/funding.service';
import MaterielService from '@/services/materiel.service';
import Materiel from '@/components/Materiel.vue';
import materielService from '@/services/materiel.service';
import Confirm from '@/components/Confirm.vue';
import FileManagerCompliance from '@/components/FileManagerCompliance.vue';
import UploadService from '@/services/upload.service';
import fileApi from '@/services/upload.service';
import complianceService from '@/services/compliance.service';
import signService from '@/services/sign.service';
import Contact from '@/components/Contact.vue';

export default {
  name: 'Stepper',
  components: { Contact, FileManagerCompliance, Materiel, Confirm },
  data() {
    return {
      loader: {
        contrat: false,
        pv: false,
        cancel: false
      },
      signStyle: {
        primary: '#23A638',
        secondary: 'lightgray',
        text: 'grey',
        value: 0
      },
      documentRequired: [],
      documentSent: [],
      products: [],
      show: false,
      ededocContractSign: null,
      currentMateriel: null,
      currentContact: null,
      showModal: false,
      showModalContact: false,
      showModalConfirm: false,
      showModalConfirmCancel: false,
      showModalConfirmFinal: false,
      send: false,
      contratDownload: null,
      compliance: null,
      steps: [
        {
          id: 1,
          title: 'Informations',
          finish: false,
          editable: true
        },
        {
          id: 2,
          title: 'Signature',
          finish: false,
          editable: false
        },
        {
          id: 3,
          title: 'Dossier',
          finish: false,
          editable: false
        },
        {
          id: 4,
          title: 'Terminé',
          finish: false,
          editable: false
        }
      ],
      currentStep: 1,
      contratContact: null,
      contacts: [],
      simulation: null,
      materiels: []
    };
  },
  computed: {
    clientClass() {
      let status = this.simulation.contract_status;
      return {
        'btn-actif': [2,3,4].includes(status),
        'btn-red': status === 5
      };
    },
    koesioClass() {
      let status = this.simulation.contract_status;
      return {
        'btn-actif': [3,4].includes(status),
        'btn-red': status === 5
      };
    },
    pvManual(){
      return !!this.documentSent.find((element) => {
        return element === 'pv';
      });
    },
    currentDocumentRequired(){
      return this.documentRequired.filter((document) => !this.documentSent.includes(document));
    },
    authorizeSendN2(){
      return this.pvManual || this.simulation.delivery_report_status === 4
    }
  },
  mounted() {
    let script = document.createElement('script');
    script.setAttribute('src', 'https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js');
    document.head.appendChild(script);
    this.emitter.on('contactUpdated', () => {
      this.getContact();
      this.getSimulation();
    });
    this.emitter.on('materielUpdated', () => {
      this.getMateriel();
    });
    this.getContact();
    this.getMateriel();
    this.getProduct();
    this.getSimulation();
  },
  methods: {
    async download(type = 'Contrat') {
      this.$notify({
        duration: 1500,
        text: `Chargement du ${type} en cours`,
        type: 'success'
      });
      if (type === 'Contrat') {
        this.getContrat();
      } else {
        this.getPv();
      }
      /* eslint-disable */
    },
    modalContact(contact) {
      if (contact !== null) {
        let contacts = this.contacts.filter((item) => {
          return item.id === contact;
        });
        this.currentContact = Object.values(contacts)[0];
      } else {
        this.currentContact = Object.values(this.currentContact)[0];
      }
      this.showModalContact = true;
    },
    getContrat() {
      this.contratDownload = null;
      UploadService.getContratFile(this.$route.params.idFunding).then((response) => {
        fileApi.downloadSimulationFile(this.$route.params.idFunding, Object.values(response.data)[0].name).then((response) => {
          this.contratDownload = 'data:' + response.data['content-type'] + ';base64, ' + response.data.content;
        });
      });
    },
    getPv() {
      this.contratDownload = null;
      UploadService.getPvFile(this.$route.params.idFunding).then((response) => {
        fileApi.downloadSimulationFile(this.$route.params.idFunding, Object.values(response.data)[0].name).then((response) => {
          this.contratDownload = 'data:' + response.data['content-type'] + ';base64, ' + response.data.content;
        });
      });
    },
    async generateContract() {
      this.contratDownload = null;
      UploadService.generateDocument(this.$route.params.idFunding, 'holothurie')
        .then(() => {
          UploadService.generateDocument(this.$route.params.idFunding, 'palourde').then(() => {
            this.$notify({
              text: 'Les pièces ont bien été générées',
              type: 'success',
              duration: 3000
            });
          })
        })
        .catch(() => {
          this.$notify({
            text: 'Erreur lors de la génération du contrat',
            type: 'error',
            duration: 3000
          });
        });
    },
    async getSimulation() {
      await fundingService
        .getFunding(this.$route.params.idFunding)
        .then(async (response) => {
          this.simulation = response.data;
          await this.getRequiredDoc();
          this.contratContact = response.data.front_infos.contacts[0]?.id;
          this.checkContratSign();
          if (!this.show) {
            await this.getCompliance();
            await this.initStep();
          }
          this.show = true;
        })
        .catch((e) => {
          this.$notify({
            text: 'Erreur lors de la récupération de la simulation',
            type: 'error',
            duration: 3000
          });
        });
    },
    getRequiredDoc() {
      signService
        .getDocList(this.simulation.rowid)
        .then((response) => {
          this.documentRequired = response.data;
        })
        .catch(() => {
          this.$notify({
            text: 'Erreur lors de la récupération des documents necessaire à la conformité',
            type: 'error',
            duration: 3000
          });
        });
    },
    setDocumentSent(docs) {
      this.documentSent = docs;
    },
    addDocumentSent(docs) {
      this.documentSent.push(docs);
    },
    getProduct() {
      materielService
        .getProduct()
        .then((response) => {
          this.products = response.data;
        })
    },
    addContact() {
      if (this.simulation.front_infos.contacts[0]?.rowid) {
        this.deleteSimulationContact();
      } else {
        this.addSimulationContact();
      }
    },
    addSimulationContact() {
      fundingService
        .postContact(this.$route.params.idFunding, this.contratContact)
        .then(() => {
          this.$notify({
            text: 'Contact mis à jour pour la simulation',
            type: 'success',
            duration: 3000
          });
          this.getSimulation();
        })
        .catch(() => {
          this.$notify({
            text: 'Erreur lors de la mis à jour du contact',
            type: 'error',
            duration: 3000
          });
        });
    },
    deleteSimulationContact() {
      fundingService.deleteContact(this.$route.params.idFunding, this.simulation.front_infos.contacts[0]?.rowid).then(() => {
        this.addSimulationContact();
      });
    },
    checkContratSign() {
      let status = this.simulation.contract_status;
      if (status === 0 || status === 1) {
        this.signStyle.value = '0';
      }
      if (status === 2) {
        this.signStyle.value = '50';
      }
      if (status === 3) {
        this.signStyle.value = '100';
      }
      if (status === 5) {
        this.signStyle.value = '0';
        this.signStyle.primary = 'indianred';
      }
    },
    modalMateriel(materiel) {
      if (this.materiels.length >= 20 && !materiel) {
        this.$notify({
          text: 'Nombre maximum de matériels saisies',
          type: 'error',
          duration: 3000
        });
        return;
      }
      this.currentMateriel = materiel;
      this.showModal = true;
    },
    async nextStep() {
      let currentStep = this.steps[this.currentStep - 1];
      if (currentStep) {
        if (currentStep.id === 1) {
          await this.generateContract();
          await this.getContrat();
        }
        if (currentStep.id === 2) {
          await this.startCompliance();
          await this.download('PV');
        }
        this.steps[this.currentStep - 1].finish = true;
        this.steps[this.currentStep].editable = true;
        this.currentStep++;
      }
    },
    previousStep() {
      for (let i = this.currentStep; i <= this.steps.length; i++) {
        this.steps[i - 1].editable = false;
        this.steps[i - 1].finish = false;
      }
      this.currentStep--;
    },
    getContact() {
      ContactService.getContacts(this.$route.params.id)
        .then((response) => {
          this.contacts = response.data;
        })
        .catch(() => {
          this.contacts = [];
        });
    },
    getMateriel() {
      MaterielService.getMateriel(this.$route.params.idFunding)
        .then((response) => {
          this.materiels = response.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            return;
          }
          this.$notify({
            text: 'Erreur lors de la récupération des materiels',
            type: 'error',
            duration: 3000
          });
        });
    },
    async startCompliance() {
      if (!this.compliance) {
        await complianceService.postCompliance(this.simulation.reference)
            .then(async () => {
              await this.getCompliance();
            })
            .catch(() => {
              this.$notify({
                text: 'Erreur lors de la géneration de la conformité',
                type: 'error',
                duration: 3000
              });
            });
      }
    },
    async getCompliance() {
      await complianceService
        .getCompliance(this.simulation.rowid)
        .then((response) => {
          this.compliance = response.data[0] ?? null;
        })
        .catch(() => {
          this.$notify({
            text: 'Erreur lors de la récupération de la conformité',
            type: 'error',
            duration: 3000
          });
        });
    },
    cancelEdedocSign() {
      this.loader.cancel = true;
      signService
        .cancelEdedocContrat(this.simulation.rowid)
        .then(() => {
          this.loader.cancel = false;
          this.getSimulation();
        })
        .catch(() => {
          this.loader.cancel = false;
          this.$notify({
            text: "Erreur lors de l'annulation de la signature éléctronique du contrat",
            type: 'error',
            duration: 3000
          });
        });
    },
    startEdedocSign() {
      this.loader.contrat = true;
      signService
        .postEdedocContrat(this.simulation.rowid)
        .then(() => {
          this.loader.contrat = false;
          this.getSimulation();
        })
        .catch(() => {
          this.loader.contrat = false;
          this.$notify({
            text: 'Erreur lors de la procédure de signature éléctronique du contrat',
            type: 'error',
            duration: 3000
          });
        });
    },
    startPVSign() {
      this.loader.pv = true;
      signService
        .postEdedocPv(this.simulation.rowid)
        .then(() => {
          this.loader.pv = false;
          this.getSimulation();
        })
        .catch(() => {
          this.loader.pv = false;
          this.$notify({
            text: 'Erreur lors de la procédure de signature éléctronique du PV',
            type: 'error',
            duration: 3000
          });
        });
    },
    async initStep() {
      if (this.simulation?.front_infos.contacts[0]?.rowid && this.materiels.length >= 1 && !this.compliance){
        this.steps[0].finish = true;
        this.currentStep = 2;
        await this.getContrat();
      }
      if (this.compliance) {
        this.steps[0].finish = true;
        this.steps[1].finish = true;
        this.currentStep = 3;
        await this.download('PV');
      }
      if (this.compliance?.status === 5 || this.compliance?.status === 8) {
        this.steps[0].finish = true;
        this.steps[1].finish = true;
        this.steps[2].finish = true;
        this.steps[3].finish = true;
        this.currentStep = 4;
      }
    },
    sendConformiteN2() {
      complianceService
        .postN1Compliance(this.compliance.rowid, this.$store.getters.getUser.userId)
        .then(() => {
          this.getCompliance();
          this.$notify({
            text: 'La demande de conformité a bien été envoyée',
            type: 'success',
            duration: 3000
          });
        })
        .catch(() => {
          this.$notify({
            text: "Erreur lors de l'envoi de la demande de conformité",
            type: 'error',
            duration: 3000
          });
        });
      //complianceService.postN2Compliance(this.compliance.rowid, this.simulation.reference).then(() => {
      //  this.getCompliance();
      //  this.$notify({
      //    text: "La demande de conformité a bien été envoyée",
      //    type: "success",
      //    duration: 3000,
      //  })
      //}).catch(() => {
      //  this.$notify({
      //    text: "Erreur lors de l'envoi de la demande de conformité",
      //    type: "error",
      //    duration: 3000,
      //  })
      //});
    },
    checkStep(steps) {
      if (steps === 1) {
        return !this.simulation?.front_infos.contacts[0]?.rowid || this.materiels < 1;
      }
      if (steps === 2) {
        return !(this.simulation.contract_status === 3);
      }
      return true;
    },

    checkStepBackward(steps) {
      if (steps === 2) {
        return !(this.simulation.contract_status === 0 || this.simulation.contract_status === -1);
      }
      return true;
    }
  }
};
</script>

<style scoped>
.info-center {
  justify-content: center;
}
.height-40 {
  min-height: 40%;
}

.height-30 {
  min-height: 30%;
}

.btn-stepper {
  cursor: auto !important;
}

.icon-large-fa {
  padding-top: 10%;
  padding-bottom: 10%;
  font-size: xxx-large;
}

.height-30 {
  min-height: 30%;
}

.svg-signature {
  color: #6e398e;
}

.min-vh-55 {
  min-height: 55vh;
}

.statut {
  margin-top: 30px !important;
}

.arrow-right {
  text-align: center;
  align-self: center;
  margin: auto;
}

.min-vh-20 {
  min-height: 10vh;
  margin-bottom: 10px !important;
}

.b-signature:hover {
  color: white !important;
  background-color: lightslategray;
}

.b-signature:hover .svg-signature {
  color: white !important;
}

.b-signature:hover .purple-loader {
  color: white !important;
}

.b-signature:hover p {
  color: white !important;
}

.b-signature:hover [role='progressbar'] {
  --primary: white !important;
  --text: white !important;
}

.b-signature:hover h3 {
  color: white !important;
}

.header {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.red {
  color: indianred;
}

.purple-loader {
  color: #6e398e;
}

.green {
  color: #23a638;
}

.step-header {
  padding-bottom: 1rem;
}

.content-stepper {
  min-height: 55vh;
}

.stepper:not(:last-child):after {
  content: '';
  height: 2px;
  display: block;
  background-color: #e0e0e0;
  position: relative;
  order: -1;
  left: calc(100% - 30px);
  top: -72px;
  width: 5vw;
}

.header {
  place-content: space-around;
}
.stepper {
  width: 200px;
  text-align: center;
}
.btn-stepper.active {
  background-color: #6e398e;
  border-color: #6e398e;
}
.btn-stepper.active svg {
  color: white;
}
p.active {
  text-decoration: underline;
  color: #6e398e;
}
.btn-stepper {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  background-color: lightslategray;
}

.btn-stepper.finish {
  background-color: #23a638 !important;
}

.btn-stepper.finish svg {
  color: white !important;
}

.footer {
  justify-content: right;
}

.margin-btn {
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}

.valdiator {
  display: flex;
  text-align: center;
  place-items: center;
  place-content: center;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: fit-content;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
}

.modal-body {
  margin: 20px 0;
}

.m-r-auto {
  margin-right: auto !important;
}

.m-l-auto {
  margin-left: auto !important;
}
.modal-default-button {
  float: right;
}

.purple-button:hover {
  border: 1px solid;
}

.btn-actif {
  background-color: #23a638 !important;
  color: white;
}

.btn-none {
  background-color: #6c757d;
  color: white;
}

.btn-red {
  background-color: indianred !important;
  color: white;
}

.center-d-flex {
  align-items: center;
  margin: auto;
  place-content: center;
}

.progress-div {
  width: 40%;
  margin: auto;
}

@keyframes progress {
  0% {
    --percentage: 0;
  }
  100% {
    --percentage: var(--value);
  }
}

@property --percentage {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

[role='progressbar'] {
  --percentage: var(--value);
  --primary: #369;
  --secondary: #adf;
  --size: 100%;
  animation: progress 3s 0.5s forwards;
  aspect-ratio: 1;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: grid;
  place-items: center;
}

[role='progressbar']::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(var(--primary) calc(var(--percentage) * 1%), var(--secondary) 0);
  mask: radial-gradient(white 55%, transparent 0);
  mask-mode: alpha;
  -webkit-mask: radial-gradient(#0000 55%, #000 0);
  -webkit-mask-mode: alpha;
}

[role='progressbar']::after {
  counter-reset: percentage var(--value);
  content: counter(percentage) '%';
  font-family: Helvetica, Arial, sans-serif;
  font-size: calc(var(--size) * 1.5);
  color: var(--text);
}
</style>
