class ConformiteStatus {
    status = [
        {
            id: 0,
            color: '#EE7202',
            icon: 'ellipsis-h',
            text: 'Brouillon',
            hidden: false,
        },
        {
            id: 1,
            color: '#6f42c1',
            icon: 'ellipsis-h',
            text: 'En attente de validation',
            hidden: true,
        },
        {
            id: 2,
            color: '#6f42c1',
            icon: 'ellipsis-h',
            text: 'En attente de validation',
            hidden: true,
        },
        {
            id: 3,
            color: '#EA4B58',
            icon: 'ellipsis-h',
            text: 'Dossier non conforme N1',
            hidden: true,
        },
        {
            id: 4,
            icon: 'ellipsis-h',
            color: '#6f42c1',
            text: 'Dossier en attente',
            hidden: false,
        },
        {
            id: 5,
            icon: 'check',
            color: '#23A638',
            text: 'Dossier Validé',
            hidden: false,
        },
        {
            id: 6,
            icon: 'times',
            color: '#EA4B58',
            text: 'Dossier non conforme',
            hidden: true,
        },
        {
            id: 7,
            icon: 'times',
            color: '#808080',
            text: 'Dossier classé sans suite',
            hidden: true,
        },
        {
            id: 8,
            icon: 'check',
            color: '#23A638',
            text: 'Dossier Cloturé',
            hidden: true,
        },
    ]
}

export default new ConformiteStatus;