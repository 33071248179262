import authHeader from './authHeader';


class PartenaireService {

    async getPartenaire(id) {
        return  authHeader.apiUrl(true)
            .get("/multicompany/"+ id, )
    }
}
export default new PartenaireService();


