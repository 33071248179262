<template>
  <div class="steps">
      <progress id="progress" value=0 max=100 ></progress>
      <!--  first item  -->
      <div class="step-item">
        <el-popover
            placement="bottom-start"
            popper-class="font-weight-bold"
            title="Simulation"
            :width="220"
            :hide-after="300"
            trigger="hover"
        >
          <hr />
          <div>
            <div class="d-flex flex-row">
              <button :style="`background-color: ${getFunding.colorhex}`" class="step-button-validated text-center" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseOne" aria-controls="collapseOne"
              >
                <fa  :icon="getFunding.icon" class="fa-xl mx-auto"></fa>
              </button>
              <span class="my-auto ml-3">{{ getFunding.text }}</span>
            </div>

          </div>
          <template #reference>
            <button :style="`background-color: ${getFunding.colorhex}`" class="step-button-validated text-center" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseOne" aria-controls="collapseOne"
            >
              <fa :icon="getFunding.icon" class="fa-xl"></fa>
            </button>
          </template>
        </el-popover>
      </div>

      <!--  2nd items  -->
      <div @click="redirectIfExist(accordIsValid, { name: 'stepper', params: { id: this.row.societe.id, idFunding: this.row.rowid } })" class="step-item">
        <el-popover
            placement="bottom-start"
            popper-class="font-weight-bold"
            title="Contrat"
            :width="350"
            :hide-after="300"
            trigger="hover"
        >
          <hr />
            <div v-for="(contract) in ContractSteps" :key="contract.id" class="d-flex flex-row">
              <template v-if="!getContractInfo.hidden">
              <div v-if="!contract.hidden" class="mb-2">
                <button :style="`background-color: ${accordIsValid ? getContratColour(contract) : 'white'}`" class="step-button-validated text-center" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-controls="collapseOne"
                >
                  <fa v-if="contratIsValid || contratIsPrioritaire(contract)" icon="check" class="fa-xl"></fa>
                  <fa v-else :icon="getContractInfo?.icon ?? 'check'" class="fa-xl mx-auto"></fa>
                </button>
                <span :class="(getContractInfo.id >= contract.id) && accordIsValid || contratIsValid ? '' : 'text-black-50'" class="my-auto align-middle ml-3">{{ contract.text }}</span>
              </div>
              </template>
              <div v-else-if="contract.id === getContractInfo.id" class="mb-2">
                <button :style="`background-color: ${getContratColour(contract)}`"  class="step-button-validated text-center" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-controls="collapseOne">
                  <fa :icon="getContractInfo?.icon ?? 'check'" class="fa-xl mx-auto"></fa>
                </button>
                <span class="my-auto text-black-50 align-middle ml-3">{{ contract.text }}</span>
              </div>
            </div>

          <template #reference>
            <button :style="`background-color: ${accordIsValid ? ContratItemColor : 'white'}`" class="step-button-validated text-center" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseOne" aria-controls="collapseOne"
            >
              <fa :icon="getContractInfo.icon" class="fa-xl"></fa>
            </button>
          </template>
        </el-popover>
      </div>

      <!--  Third items  -->
      <div @click="redirectIfExist(contratIsValid, { name: 'stepper', params: { id: this.row.societe.id, idFunding: this.row.rowid } })" class="step-item">
        <el-popover
            placement="bottom-start"
            popper-class="font-weight-bold"
            title="PV"
            :width="350"
            :hide-after="300"
            trigger="hover"
        >
          <hr />
          <div v-for="(proces_verbale, name) in pvSteps" :key="name" class="d-flex flex-row">
            <template v-if="!getPvInfo.hidden">
            <div v-if="!proces_verbale.hidden" class="mb-2">
              <button :style="`background-color: ${contratIsValid && accordIsValid ? getPvColor(proces_verbale) : 'white'}`" class="step-button-validated text-center" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseOne" aria-controls="collapseOne"
              >
                <fa v-if="pvIsValid || pvIsPrioritaire(proces_verbale)" icon="check" class="fa-xl"></fa>
                <fa v-else :icon="getPvInfo?.icon ?? 'check'" class="fa-xl mx-auto"></fa>
              </button>
              <span :class="(contratIsValid && getPvInfo.id >= proces_verbale.id) || pvIsValid ? '' : 'text-black-50'" class="my-auto align-middle ml-3">{{ proces_verbale.text }}</span>
            </div>
            </template>
            <div v-else-if="getPvInfo.id === proces_verbale.id" class="mb-2">
              <button :style="`background-color: ${getPvColor(proces_verbale)}`" class="step-button-validated text-center" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseOne" aria-controls="collapseOne"
              >
                <fa :icon="getPvInfo?.icon" class="fa-xl mx-auto"></fa>
              </button>
              <span class="my-auto text-black-50 align-middle ml-3">{{ proces_verbale.text }}</span>
            </div>
          </div>

          <template #reference>
            <button :style="`background-color: ${contratIsValid && accordIsValid ? pvItemColor : 'white'}`" class="step-button-validated text-center" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseOne" aria-controls="collapseOne"
            >
              <fa :icon="getPvInfo.icon" class="fa-xl"></fa>
            </button>
          </template>
        </el-popover>
      </div>

      <!--  Fourth Item  -->
      <div @click="redirectIfExist(contratIsValid, { name: 'stepper', params: { id: this.row.societe.id, idFunding: this.row.rowid } })" class="step-item">
        <el-popover
            placement="bottom-start"
            popper-class="font-weight-bold"
            title="Dossier"
            :width="350"
            :hide-after="300"
            trigger="hover"
        >
            <div v-for="(conformite, name) in conformiteSteps" :key="name" class="d-flex flex-row">
            <template v-if="!getConformiteInfo.hidden">
            <div v-if="!conformite.hidden" class="mb-2">
              <button :style="`background-color: ${pvIsValid && accordIsValid ? getConformiteColor(conformite) : 'white'}`" class="step-button-validated text-center" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseOne" aria-controls="collapseOne"
              >
                <fa v-if="conformiteIsPrioritaire(conformite)" icon="check" class="fa-xl"></fa>
                <fa v-else :icon="getConformiteInfo?.icon ?? 'check'" class="fa-xl mx-auto"></fa>
              </button>
              <span :class="(contratIsValid && getPvInfo.id >= conformite.id) || pvIsValid ? '' : 'text-black-50'" class="my-auto align-middle ml-3">{{ conformite.text }}</span>
            </div>
          </template>
            <div v-else-if="getConformiteInfo.id === conformite.id" class="mb-2">
              <button :style="`background-color: ${getConformiteColor(conformite)}`" class="step-button-validated text-center" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseOne" aria-controls="collapseOne"
              >
                <fa :icon="getConformiteInfo?.icon ?? 'check'" class="fa-xl mx-auto"></fa>
              </button>
              <span class="my-auto text-black-50 align-middle ml-3">{{ conformite.text }}</span>
              </div>
            </div>

          <template #reference>
            <button :style="`background-color: ${pvIsValid && accordIsValid ? conformiteItemColor : 'white'}`" class="step-button-validated text-center" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseOne" aria-controls="collapseOne"
            >
              <fa :icon="getConformiteInfo.icon" class="fa-xl"></fa>
            </button>
          </template>
        </el-popover>
      </div>
    </div>
</template>

<script>
/* eslint-disable */
import Funding from "@/class/funding";
import ContratStatut from '@/class/ContratStatus';
import DeliveryReportStatus from '@/class/DeliveryReportStatus';
import ConformiteStatus from '@/class/ConformiteStatus';

const VALIDATED_COLOR = '#23A638';
const NOT_VALID_COLOR = '#FFFFFF';

export default {
  name: "InternalStepper.vue",
  props: {
    row: {
      required: true
    }
  },
  methods: {
    getContratColour(contract) {
      if (contract.id < this.getContractInfo.id || this.getContrat === 3 || this.contratIsValid)
        return VALIDATED_COLOR;
      else if (contract.id === this.getContractInfo.id)
        return contract.color
      else
        return NOT_VALID_COLOR;
    },
    getConformiteColor(conformite) {
        if ([5,8].includes(this.getConformite) || conformite.id < this.getConformite)
          return VALIDATED_COLOR;
        else if (conformite.id === this.getConformite)
          return conformite.color
        else
          return NOT_VALID_COLOR;
    },
    getPvColor(pv) {
      if (this.getConformiteInfo?.id >= 4 || pv.id < this.getPvInfo.id)
        return VALIDATED_COLOR;
      else if (pv.id === this.getPvInfo.id)
        return pv.color
      else
        return NOT_VALID_COLOR;
    },
    contratIsPrioritaire(contract) {
      return (contract.id < this.getContractInfo.id);
    },
    pvIsPrioritaire(pv) {
      return (pv.id < this.getPvInfo.id);
    },
    conformiteIsPrioritaire(conformite) {
      return (conformite?.id < this.getConformiteInfo?.id || [8,5].includes(conformite?.id))
    },
    redirectIfExist(state, params) {
      if (state) {
        this.$router.push(params)
      }
      else {
        this.$notify({
          duration: 5000,
          text: 'Vous ne respectez pas les conditions pour accéder à cette page',
          type: 'warning'
        });
      }
    },
  },
  computed: {
    getAccord() {
      return this.row.accord;
    },
    conformiteIsValid() {
      return this.getConformite === 5 || this.getConformite === 8;
    },
    accordIsValid() {
      return Funding.validStatusDatatable.includes(this.getAccord);
    },
    contratIsValid() {
      return this.getContrat === 4;
    },
    pvIsValid() {
      return this.getPv === 4;
    },
    getFunding() {
      return Funding.status[this.row.accord];
    },
    getContrat() {
      return this.row.contract_status;
    },
    getPv() {
      return this.row.delivery_report_status;
    },
    getConformite() {
      return this.row.conformite.status;
    },
    getContractInfo() {
      return ContratStatut.status.find((element) => {
        return element.id === this.getContrat;
      });
    },
    getConformiteInfo() {
      let status =  ConformiteStatus.status.find((element) => {
        return element.id === this.getConformite;
      });
      if(!status){
        return ConformiteStatus.status.find((element) => {
          return element.id === 0;
        });
      }
      return status
    },
    getPvInfo() {
      return DeliveryReportStatus.status.find((element) => {
        return element.id === this.getPv;
      });
    },
    ContratItemColor() {
      let color;
      if ((this.getContrat === 4 && this.row.delivery_report_status >= 0) || this.contratIsValid){
        return VALIDATED_COLOR;
      }
      try {
        color = this.getContractInfo.color;
        return color;
      } catch (e) {
        return '';
      }
    },
    conformiteItemColor() {
      let color;
      try {
        color = this.getConformiteInfo?.color;

        return color;
      } catch (e) {
        return '';
      }
    },
    pvItemColor() {
      let color;
      try {
        if (this.getConformiteInfo?.id >= 4) {
          return VALIDATED_COLOR;
        }
        color = this.getPvInfo.color;

        return color;
      } catch (e) {
        return '';
      }
    },
    ContractSteps() {
      return ContratStatut.status;
    },
    pvSteps() {
      return DeliveryReportStatus.status;
    },
    conformiteSteps() {
      return ConformiteStatus.status;
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  position: relative;
}
.step-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-width: 4px;
  border-color: #D4D4D4;
  border-style: solid;
  background-color: white;
  transition: .4s;
}
.step-button-validated {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-width: 4px;
  border-color: #D4D4D4;
  border-style: solid;
  color: white;
}
.step-item {
  z-index: 10;
  text-align: center;
}
#progress {
  -webkit-appearance:none;
  position: absolute;
  width: 85%;
  z-index: 5;
  height: 6px;
  margin-left: 25px;
  margin-bottom: 2px;
}
/* to customize progress bar */
#progress::-webkit-progress-value {
  background-color: #D4D4D4;
  transition: .5s ease;
}
#progress::-webkit-progress-bar {
  background-color: #D4D4D4;

}
</style>