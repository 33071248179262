import moment from 'moment';

function capitalizeFirstLetter(chaine) {
    let lowerCase = chaine.toLowerCase();
    return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
}

function periodicityVerboseArray() {
    return {
        MOIS: 'Mois',
        TRIMESTRE: 'Trimestres',
        SEMESTRE: 'Semestres',
        ANNEE: 'Ans'
    };
}

function paymentChoices() {
    return {
        CHQ: 'Chèque',
        MDT: 'Mandat',
        PRE: 'Prélèvement',
        VIR: 'Virement'
    };
}

function contractTypes() {
    return {
        LOCSIMPLE: 'Location simple',
        FORFAITGLOBAL: 'Forfait global',
        INTEGRAL: 'Intégral',
        'INTEGRAL ECO PRINT': 'Ecoprint',
        BPA: 'Be Proactive'
    };
}
function contractPeriodicityArray() {
    return {
        MOIS: 'Mensuel',
        TRIMESTRE: 'Trimestriel',
        SEMESTRE: 'Semestriel',
        ANNEE: 'Annuel'
    };
}

let status = {
    KO: {
        icon: 'ban',
        text: 'Refus',
        color: '#fbe9eb'
    },
    OK: {
        icon: 'thumbs-up',
        text: 'Accord',
        color: '#e5f5e7'
    },
    WAIT: {
        icon: 'stopwatch',
        text: 'En étude',
        color: '#dbf2fc'
    },
    WAIT_AP: {
        icon: 'handshake',
        text: 'Accord de principe',
        color: '#e5fde8'
    },
    WAIT_LEASER: {
        icon: 'question-circle',
        text: "En attente d'éléments",
        color: '#fceee0'
    },
    WAIT_MODIF: {
        icon: 'stopwatch',
        text: 'En étude',
        color: '#dbf2fc'
    }
};

function dateFormat(date) {
    if (Object.prototype.toString.call(date) === '[object Date]') {
        return date.toLocaleDateString('en-EN');
    } else {
        return moment(date).format('DD/MM/YYYY');
    }
}

function reverseDateFormat(date) {
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();

    // Ajout d'un zéro devant le jour et le mois si nécessaire
    if (day < 10) {
        day = '0' + day;
    }
    if (month < 10) {
        month = '0' + month;
    }

    return year + '/' + month + '/' + day;
}

function percentage(value, total, round = 2) {
    const result = ((value / total) * 100).toFixed(round);

    if (isNaN(result)) return -1;

    return `${result} %`;
}

export {
    capitalizeFirstLetter,
    periodicityVerboseArray,
    paymentChoices,
    contractTypes,
    contractPeriodicityArray,
    dateFormat,
    reverseDateFormat,
    status,
    percentage
};
