import axios from "axios";


class AuthHeader {

    apiUrl(){
        const api_Url = axios.create({
            baseURL :"/api",
            headers: this.tokenUser()
        })
        return api_Url
    }

    tokenUser(){
        return {
            Accept: 'application/json',
            DOLAPIKEY:  JSON.parse(localStorage.getItem("vuex")).token
        };
    }

}

export default new AuthHeader();
