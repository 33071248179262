class ContratStatut {
    status = [
        {
            id: -1,
            color: '#808080',
            text: 'Annulé',
            icon: 'times',
            colorhex: '#3FC0F0',
            hidden: true,
        },
        {
            id: 0,
            color: '#EE7202',
            text: 'Attente d\'envoi',
            icon: 'ellipsis-h',
            colorhex: '#3FC0F0',
            hidden: false,
        },
        {
            id: 1,
            color: '#EE7202',
            text: 'Signature Client',
            icon: 'ellipsis-h',
            colorhex: '#3FC0F0',
            hidden: false,
        },
        {
            id: 2,
            color: '#6f42c1',
            text: 'Signature Koesio',
            icon: 'ellipsis-h',
            colorhex: '#3FC0F0',
            hidden: false,
        },
        {
            id: 4,
            color: '#23A638',
            text: 'Contrat signé',
            icon: 'check',
            colorhex: '#3FC0F0',
            hidden: false,
        },
        {
            id: 5,
            color: '#EA4B58',
            text: 'Refuse',
            icon: 'times',
            colorhex: '#3FC0F0',
            hidden: true,
        },
        {
            id: 6,
            color: '#808080',
            text: 'Expiré',
            icon: 'times',
            colorhex: '#3FC0F0',
            hidden: true,
        },
    ]
}
export default new ContratStatut