<template>
    <notifications width="400px" position="bottom right" class="my-notification" style="text-align: center; margin-top: 50px" />
    <router-view :key="$route.fullPath" name="header" />
    <BoxSupport v-if="!infoDisplay" />

    <div class="position-relative">
        <router-view :key="$route.fullPath" />
    </div>
</template>

<script setup></script>
<style></style>
<script>
import BoxSupport from "@/components/BoxSupport.vue"
export default {
  components: {BoxSupport},
  computed: {
    infoDisplay(){
      return ['login','dashboard'].includes(this.$route.name);
    }
  }
}
</script>