import authHeader from './authHeader';

class ContactService {
    getContacts(idSociete) {
        return authHeader.apiUrl()
            .get("/contacts", {
                params: {
                    sortfield: 't.rowid',
                    sortorder: 'ASC',
                    limit: 100,
                    thirdparty_ids: idSociete,
                }
            })
    }
    updateContacts(contact) {
        return authHeader.apiUrl()
            .put("/contacts/"+contact.id , {
                id: contact.id,
                firstname: contact.firstname,
                lastname: contact.lastname,
                phone_mobile: contact.phone_pro,
                email: contact.email,
                poste: contact.fonction,
            })
    }
    createContacts(contact, societe) {
        return authHeader.apiUrl()
            .post("/contacts" , {
                socid: societe,
                firstname: contact.firstname,
                lastname: contact.lastname,
                phone_mobile: contact.phone_pro,
                email: contact.email,
                poste: contact.fonction,
            })
    }
    deleteContacts(idContact) {
        return authHeader.apiUrl()
            .delete("/contacts/"+idContact)
    }
}

export default new ContactService();