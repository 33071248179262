<template>
  <div class="d-flex justify-content-center align-items-center tooltipAvatar">
      <span class="rounded-circle d-flex justify-content-center align-items-center small header-round base-color" :class="{ avatarFinancement: this.avatarFinancement }">
           <span class="position-relative"><fa class="text-2em " icon='user'></fa></span>
      </span>
    <div v-if="hover" class="top">
     {{ firstname }} {{ lastname }}
      <i></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    size: {
      type: String,
      default: 'medium'
    },
    avatarFinancement: {
      type: Boolean,
      default: false
    },
    withBadge: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {

    }
  },
}
</script>

<style scoped>

.base-color{
  background: white;
}
.base-color svg{
  color: lightgrey;
}

.avatarFinancement{
  background: lightcoral !important;
}

.avatarFinancement svg{
  color: white !important;
}

.base-color:hover{
  background: lightgrey !important;
}

.base-color:hover svg{
  color: white !important;
}


.small {
  width: 2.5rem;
  height: 2.5rem;
  display: block;
  font-size: 12px;
  margin-left: calc(-9px);
  margin-right: calc(-2px);
}

.tooltipAvatar {
  display: inline-block;
  position: relative;
  text-align: left;
}

.tooltipAvatar h3 {
  margin: 7px 0;
}

.tooltipAvatar .top {
  width: max-content;
  top: -10px;
  left: 30%;
  transform: translate(-30%, -100%);
  padding: 10px 10px;
  color: #ffffff;
  background-color: #082C42;
  font-weight: normal;
  font-size: 14px;
  border-radius: 8px;
  position: absolute;
  z-index: 2;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  display: none;
}

.tooltipAvatar:hover .top {
  display: block;
}

.center{
  margin-left: 10px;
  margin-top: 6px;
}

.tooltipAvatar .top i {
  position: absolute;
  top: 100%;
  left: 30%;
  margin-left: -15px;
  width: 30px;
  height: 15px;
  overflow: hidden;
}

.tooltipAvatar .top i::after {
  content: '';
  position: absolute;
  width: 9px;
  height: 9px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #0c517c;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}


</style>
