<template>
  <div class="container">
    <div class="head">
      <h5 class="text-uppercase font-weight-bold p-2">Contrat de financement en cours</h5>
      <fa icon="handshake" class="fa-large fa-xl purple"></fa>
    </div>
    <hr />
    <div class="position-relative">
      <Loading :value="100" bar-class="bg-info" v-if="!show"></Loading>
    </div>
    <div v-if="show">
      <div v-if="contracts.length">
        <div id="filter" class="mb-4">
          <div class="form-row d-none">
            <div class="col">
              <select class="form-control" v-model="filter.contractValue">
                <option v-for="option in filter.contractOptions" :value="option" :key="option">
                  {{ capitalizeFirstLetter(option) }}
                </option>
              </select>
            </div>
            <div class="col-12 position-relative ml-2">
              <input type="range" class="formControlRange" v-model="filter.minRangeValue" min="0" :max="filter.maxRange" />
              <input type="range" class="formControlRange" v-model="filter.maxRangeValue" min="0" :max="filter.maxRange" />
              <div class="d-flex justify-content-between mb-5">
                <div class="text-muted">Loyer min :{{ vueNumberFormat(filter.minRangeValue, { thousand: ' ', precision: 0 }) }}</div>
                <div class="text-muted">Loyer max :{{ vueNumberFormat(filter.maxRangeValue, { thousand: ' ', precision: 0 }) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="contractFiltered.length">
          <ContractCard v-for="contract in contractFiltered" :key="contract.id" :contract="contract" :partner="partner" class="mb-3"></ContractCard>
        </div>
        <div v-else class="text-muted text-1em text-center">
          <lottie-player
            :src="require('@/assets/empty.json')"
            background="transparent"
            class="m-auto"
            speed="1"
            style="width: 400px; height: 400px"
            loop
            autoplay
          ></lottie-player>
          Aucun contrat ne correspond aux critères de votre recherche
          <div class="alert col-4 m-auto alert-purple" role="alert">Aucun contrat ne correspond aux critères de votre recherche</div>
        </div>
      </div>
      <div v-else class="align-items-center justify-content-center mt-3">
        <lottie-player
          :src="require('@/assets/empty.json')"
          background="transparent"
          class="m-auto"
          speed="1"
          style="width: 400px; height: 400px"
          loop
          autoplay
        ></lottie-player>
        <div class="alert col-4 m-auto alert-purple" role="alert">Aucun contrat de financement en cours</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Filter from '../class/filter';
import { watch, ref, defineProps } from 'vue';
import Loading from './Loading';
import ContractCard from './ContractProgressCard';
import ContractService from '../services/contract.service';
import FinancementService from '../services/funding.service';
import { useRoute } from 'vue-router';

const props = defineProps({
  partner: {
    default: null
  }
});
const router = useRoute();

let filter = ref(Filter);
let partner = ref(props.partner);
let contracts = ref(null);
let show = ref(false);
let contractFiltered = ref(null);

(async () => {
  contracts.value = await getContract(router.params.siret);
  if (contracts.value) {
    await constructorFilter(contracts.value);
    await constructorPeriod(contracts.value);
    contractFiltered.value = await getFilteredClient(contracts.value);
  }
  show.value = true;
})();

watch(filter.value, () => {
  contractFiltered.value = getFilteredClient(contracts.value);
});

async function getContract(id) {
  try {
    let contract = await ContractService.getContracts(id);
    return contract.data;
  } catch {
    return null;
  }
}

function constructorFilter(contracts) {
  contracts.filter((contract) => {
    filter.value.maxRangeValue = contract.echeance >= filter.value.maxRangeValue ? Math.ceil(contract.echeance) : filter.value.maxRangeValue;
    filter.value.maxRange = filter.value.maxRangeValue;
    filter.value.contractOptions = contract.type_contrat;
    filter.value.leaserOptions = contract.leaser.name;
  });
}

async function constructorPeriod(contract) {
  for (const [i, financement] of contract.entries()) {
    await FinancementService.getPayments(financement.id).then((payments) => {
      contracts.value[i].balanceAfterPayment = payments.data.filter((payment) => isPeriodAfterPayment(payment, financement));
      contracts.value[i].watchPeriod = parseInt(contracts.value[i].balanceAfterPayment.length) > 1;
    });
  }
}

function isPeriodAfterPayment(payment, financement) {
  return (
    payment.period === (financement.financementLeaser.duree_passe ?? financement.financement.duree_passe) ||
    payment.period === (financement.financementLeaser.duree_passe ?? financement.financement.duree_passe) + 1
  );
}

function getFilteredClient(contract) {
  return filterProductsByRange(filterContractByType(filterContractByLeaser(contract)));
}

function filterContractByLeaser(contracts) {
  if (contracts.length && filter.value.leaserValue !== 'Tous les leasers') {
    return contracts.filter((contract) => contract.leaser.name === filter.value.leaserValue);
  }
  return contracts;
}

function filterContractByType(contracts) {
  if (contracts.length && filter.value.contractValue !== 'Tous les types de contrat') {
    return contracts.filter((contract) => contract.type_contrat === filter.value.contractValue);
  }
  return contracts;
}

function filterProductsByRange(contracts) {
  return contracts.filter((contract) => contract.echeance >= filter.value.minRangeValue && contract.echeance <= filter.value.maxRangeValue);
}

function capitalizeFirstLetter(chaine) {
  if (!chaine) {
    return '';
  }
  let lowerCase = chaine.toLowerCase();
  return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
}
</script>

<style scoped>
hr {
  margin-top: 0 !important;
  background-color: #6e398e;
  height: 1px;
}

.formControlRange {
  appearance: auto;
  cursor: default;
  padding: initial;
  border: initial;
}

input[type='range'] {
  position: absolute;
  left: 0;
  bottom: 30%;
}

input[type='range'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  border-radius: 50px;
}

input[type='range']:focus {
  outline: none;
  border-radius: 50px;
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: #6e398e;
  border-radius: 50px !important;
}

input[type='range']:focus::-ms-fill-lower {
  background: #6e398e;
}

input[type='range']:focus::-ms-fill-upper {
  background: #6e398e;
}

input[type='range']:focus::-moz-range-track {
  background-color: #6e398e;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: linear-gradient(to right, #6e398e, #6e398e);
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}

input[type='range']::-webkit-slider-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #a9a9a9;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-top: -7px;
}

input[type='range']::-moz-range-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #a9a9a9;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #fff;
  cursor: pointer;
  -moz-appearance: none;
  margin-top: -7px;
}

input[type='range']::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: linear-gradient(to right, #0083b0, #00b4db);
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}

.form-control {
  color: #a9a9a9;
}

#filter {
  padding: 10px 20px;
  border-radius: 5px;
}
</style>
