<template>
  <div class="col txt-bold">
    <h5 class="text-white text-uppercase font-weight-bold p-2"> Documents</h5>
    <template v-if="this.idSimulation">
      <el-popover placement="top-start" :width="350" :visible="showPopUp && statusIsWaitingSeller" :hide-after="3000">
        <h5 class="text-purple initial-align break">
          Notification
        </h5>
        <p class="initial-align break text-break">Vous avez la possibilité de notifier Koesio Asset Management du dépôt de nouveaux documents sur votre simulation</p>
        <button class="btn btn-purple-inverted max-content mt-2" v-on:click="notifyFinancement">Notifier le financement</button>
        <template #reference>
          <file-pond
              name="test"
              ref="pond"
              imagePreviewMinHeigh="120"
              imagePreviewMaxHeight="220"
              label-idle="Glissez et déposez vos fichiers..."
              v-bind:allow-multiple="true"
              v-bind:files="this.files"
              v-on:addfile="addNewFile"
              v-on:removefile="removeFile"
          />
        </template>
      </el-popover>
    </template>
    <template v-else>
      <p class="bg-white p-3"><i>Vous devez envoyer votre simulation pour déposer vos documents</i></p>
    </template>
  </div>
</template>

<script>

import vueFilePond from "vue-filepond";
import fileApi from "../services/upload.service";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css";
import FilePondPluginGetFile from 'filepond-plugin-get-file';
import FundingService from '../services/funding.service';


const FilePond = vueFilePond(
    FilePondPluginGetFile,
);

export default {
  components: {
    FilePond,
  },
  props: {
    simulationStatus: {
      type: String,
      required: true
    }
  },
  name: 'FileManager',
  data() {
    return {
      showPopUp: false,
      files: [],
      idSimulation: this.$route.params.idFunding,
    }
  },
  methods: {
    async getFilesFromApi(){
      await fileApi.getSimulationFile(this.idSimulation).then(async(response) => {
        for (let i = 0; i < response.data.length; i++) {
          let file = response.data[i];
          await fileApi.downloadSimulationFile(this.idSimulation, file.name).then(async(response) => {
            const sliceSize = 512;
            const byteCharacters = atob(response.data.content);
            const byteArrays = [];
            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }
            let blob = new Blob(byteArrays, {type: response.data.contentType});
            let finalFile = new File([blob], file.name, {type: response.data['content-type']});
            finalFile.baseInitFilePond = true;
            await this.$refs.pond.addFile(finalFile);
          }).catch((error) => {
            if(error.response.status === 404){
              return;
            }
            this.$notify({
              duration: 3000,
              text: 'Erreur lors de la récuperation d\'un document',
              type: 'error'
            });
          })
        }
      }).catch((error) => {
        if(error.response.status === 404){
          return;
        }
        this.$notify({
          duration: 3000,
          text: 'Erreur lors de la récuperation de la liste des documents',
          type: 'error'
        });
      });
      if(this.simulationStatus === 'OK' || this.simulationStatus === 'OK_RG' || this.simulationStatus === 'OK_FIN') {
        await fileApi.getSimulationEtude(this.idSimulation).then(async (response) => {
          let file = response.data[0];
          await fileApi.downloadSimulationFile(this.idSimulation, file.name).then(async (response) => {
            const sliceSize = 512;
            const byteCharacters = atob(response.data.content);
            const byteArrays = [];
            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }
            let blob = new Blob(byteArrays, {type: response.data.contentType});
            let finalFile = new File([blob], file.name, {type: response.data['content-type']});
            finalFile.baseInitFilePond = true;
            await this.$refs.pond.addFile(finalFile);
          }).catch((error) => {
            if (error.response.status === 404) {
              return;
            }
            this.$notify({
              duration: 3000,
              text: 'Erreur lors de la récuperation d\'un document',
              type: 'error'
            });
          })
        }).catch((error) => {
          if (error.response.status === 404) {
            return;
          }
          this.$notify({
            duration: 3000,
            text: 'Erreur lors de la récuperation de la liste des documents',
            type: 'error'
          });
        })
      }
    },
    addNewFile(error, file){
      if(!file.file.baseInitFilePond) {
        this.processBase64(file.file);
        this.showPopUp = true;
      }
    },
    sendFile(file,base64file){
      const base64String = base64file
          .replace('data:', '')
          .replace(/^.+,/, '');
        fileApi.postSimulationFile(this.idSimulation, file, base64String).then(() => {
          this.$notify({
            duration: 3000,
            text: 'Le document a été envoyé',
            type: 'success'
          });
        }).catch(() => {
          this.$notify({
            duration: 3000,
            text: 'Erreur lors de l\'envoi du document',
            type: 'error'
          });
          this.$refs.pond.removeFile(file)
      });

    },
    notifyFinancement() {
      FundingService.postSimulationStatusModification(this.idSimulation).then(() => {
        this.$notify({
          duration: 3000,
          text: 'Le service financement à été notifié de votre dépot de documents',
          type: 'success'
        });
        this.showPopUp = false;
      })
    },
    removeFile(error, file){
      fileApi.deleteSimulationFile(this.idSimulation,file.file.name)
      this.showPopUp = false;
    },
    processBase64(file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        await this.sendFile(file, reader.result)
      };
      reader.onerror = () => {
        this.$notify({
          duration: 3000,
          text: 'Erreur lors de conversion en base 64',
          type: 'error'
        });
        return false;
      };
    },
  },
  computed: {
    statusIsWaitingSeller() {
      return ['WAIT_SELLER'].includes(this.simulationStatus);
    },
  },
  mounted() {
    if(this.idSimulation){
      this.getFilesFromApi();
    }
  }
}
</script>

<style>
.filepond--credits {
  display:none !important;
}
</style>
