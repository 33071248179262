<template>
  <div id="navbar-contrat">
    <nav class="navbar navbar-expand-lg">

      <div class="navbar-collapse row pr-3 pl-3">
        <div class="navbar-nav mr-auto col-5">
          <h1 class="t-20">{{ this.client.name }}</h1>
        </div>
        <div class="dropdown show">
          <a class="btn btn-purple-inverted dropdown-toggle" v-html="this.$route.meta.name" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          </a>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <router-link :to="{name:'funding',params:{id: idCustomer,siret: idProf2Customer}}" class="btn dropdown-item">
              <fa icon="stopwatch" class="mr-2"></fa> Demandes de financement
            </router-link>
            <router-link :to="{name:'contract',params:{id :idCustomer,siret:idProf2Customer}}" class="btn dropdown-item">
              <fa icon="handshake"></fa> Contrats de financement
            </router-link>
            <router-link :to="{name:'newFunding',params:{idCustomer :idCustomer}}" class="btn dropdown-item">
              <fa icon="euro-sign" class="mr-2" />
              Nouvelle demande de financement
            </router-link>
          </div>
        </div>
        <div class="nav-item text-center text-xl-leftl col-7">
        </div>
      </div>
    </nav>
    <hr>
  </div>
</template>

<script>
export default {
  name: 'HeaderMenuCustomer',
  props: {
    client: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      idCustomer: this.$route.params.id,
      idProf2Customer: this.client.idprof2,
      name: this.client.name
    }
  },
  watch: {
    '&route.params.id'  (newId) {
      this.idCustomer = newId;
      this.idProf2Customer = this.client.idprof2;
      this.name = this.client.name;
    }
  },
  mounted() {
  }
}
</script>
<style scoped>
  hr{
    margin: 0 1rem 20px;
    background-color: black;
  }

  .router-link-active{
    background-color: #6e398e;
    color: white;
  }

  #navbar-contrat{
    width: 80%;
    margin: auto;
  }

</style>


