<template>
  <appHeaderMenu @filterDate="setDate($event)" />
  <Loading :value="100" bar-class="bg-info" v-if="!show"></Loading>
  <div class="mt-4 w-75 m-auto">
    <DataTable
      :contractType="contractType"
      :dataTable="kanban"
      :pagination="simulations.pagination"
      :loading="isSearching"
      @changePage="(pageInfo) => handlePage(pageInfo.size, pageInfo.page, pageInfo.filters)"
      @changeLimit="(pageInfo) => setDate(actualDate, pageInfo.size)"
      @filterData="(pageInfo) => handlePage(pageInfo.size, 1, pageInfo.filters)"
      @removeSimulation="(info) => refreshPage(info)"
    />
  </div>
</template>

<script>
import Loading from '../components/Loading';
import AppHeaderMenu from '../components/AppHeaderMenu';
import moment from 'moment';
import DataTable from '../components/DataTable';

export default {
  name: 'Portfolio',
  props: ['filter', 'date'],
  components: { DataTable, AppHeaderMenu, Loading },
  data() {
    return {
      show: false,
      kanban: [],
      errorMessage: null,
      noData: false,
      isSearching: false,
      actualFilters: '',
      actualLimit: 20,
      removeSimulation: true,
      simulations: {
        type: Array,
        default: []
      },
      actualDate: {
        start: null,
        max: null,
      }
    };
  },
  computed: {
    contractType() {
      return this.$store.getters.getContractTypes;
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    redirectToSimulation(row) {
      this.$router.push({ name: 'custommerFunding', params: { id: row.societe.id, idFunding: row.rowid } });
    },
    async refreshPage(info) {
      this.removeSimulation = info
      await this.handlePage(this.actualLimit, 1, this.actualFilters);
    },
    async handlePage(limit = 20, page, filters = '') {
      this.actualFilters = filters;
      this.actualLimit = limit;
      this.isSearching = true;
      setTimeout(async () => {
        this.simulations = await this.getFundings(this.actualDate.start, this.actualDate.max, limit, page - 1, filters);
        await this.sortFunding().then(() => this.isSearching = false);

      }, 1500);
    },
    async setDate(date, limit = 20, page = 1) {
      this.show = false;
      const start = date ? date.start.replaceAll('/', '') : null;
      const max = date ? date.max.replaceAll('/', '') : null;
      this.actualDate.start = start;
      this.actualDate.max = max;
      this.isSearching = true;

      setTimeout( async () => {
        this.simulations = await this.getFundings(start, max, limit, page - 1, this.actualFilters);
        this.noData = !this.simulations.data.length;
        await this.sortFunding();
        this.isSearching = false
      }, 1500)

    },
    async sortFunding() {
      this.kanban = this.simulations.data;
    },
    async getFundings(start = null, end = null, limit, page = 0, filters = '') {
      let date = start && end ? '(t.date_cre:>:' + start + ') and (t.date_cre:<:' + end + ')' : ''
      if (date !== '' && filters !== '') {
        date += ' and ';
      }
      if(this.removeSimulation){
        filters += (filters === '' || !filters.length ) ? `(t.accord:!=:'OK_FIN')` : ` and (t.accord:!=:'OK_FIN')`
      }
      try {
        const res = await this.$http.get('/api/financement/simulation', {
          params: {
            limit: limit,
            page: page,
            pagination_data: true,
            sqlfilters: date + filters
          }
        });

        return res.data;
      } catch (error) {
        this.$notify({
          duration: 3000,
          text: error.message,
          type: 'error'
        });
      }
    }
  }
};
</script>

<style scoped>
.h70vh {
  height: 70vh;
}
.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-9);
}
.el-table .success-row {
  --el-table-tr-bg-color: var(--el-color-success-light-9);
}
</style>
