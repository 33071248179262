<template>
  <div class="position-fixed" style="z-index: 1; right: 10px; bottom: 10px">
    <el-tooltip effect="dark" placement="left">
      <template #content>
        <h6 class="text-white">Support d'aide</h6>
        Relation Partenaire - relationpartenaire.kam@koesio.com - 04 27 24 00 39<br />
        Relation Client - relationclient.kam@koesio.com - 04 75 78 80 47
      </template>
      <fa icon="circle-info" bounce style="--fa-animation-duration: 2s; color: #6e398e" class="fa-3x mt-2 fa-regular" />
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: "BoxSupport"
}
</script>

<style scoped>

</style>