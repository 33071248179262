<template>
  <span class="tag">{{text}}</span>
</template>

<script>
export default {
  name: 'AppContractTag',
  props: {
      text: {
        type: String,
      }
  }
}
</script>

<style scoped>

.tag {
  background: #082c42;
  border-radius: 3px 0 0 3px;
  color: #ffffff;
  display: inline-block;
  height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}

.tag::before {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.tag::after {
  background: #fff;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #082c42;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

.tag:hover {
  background-color: crimson;
  color: white;
}

.tag:hover::after {
  border-left-color: crimson;
}
</style>
