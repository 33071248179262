import authHeader from './authHeader';

class MaterielService {
    getMateriel(idSimulation) {
        return authHeader.apiUrl()
            .get("/financement/simulation/"+idSimulation+"/materiel", {
                params: {
                    sortfield: 't.rowid',
                    sortorder: 'ASC',
                    limit: 100,
                }
            })
    }
    updateMateriel(idSimulation, materiel) {
        return authHeader.apiUrl()
            .put("/financement/simulation/"+idSimulation+"/materiel/"+materiel.rowid , {
                label: materiel.label,
                qty: materiel.qty,
                fk_condition: parseInt(materiel.state),
            })
    }
    createMateriel(idSimulation, materiel) {
        return authHeader.apiUrl()
            .post("/financement/simulation/"+idSimulation+"/materiel/" , {
                label: materiel.label,
                qty: materiel.qty,
                fk_condition: parseInt(materiel.state),
            })
    }
    deleteMateriel(idSimulation, materiel) {
        return authHeader.apiUrl()
            .delete("/financement/simulation/"+idSimulation+"/materiel/"+materiel.rowid)
    }
    getProduct() {
        return authHeader.apiUrl()
            .get("/products")
    }
}

export default new MaterielService();