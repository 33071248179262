import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faSearch,
  faSearchPlus,
  faEuroSign,
  faBuilding,
  faPencilAlt,
  faUniversity,
  faCalendar,
  faCalendarCheck,
  faStopwatch,
  faQuestionCircle,
  faHandshake,
  faThumbsUp,
  faBan,
  faMapMarker,
  faBarcode,
  faArrowRight,
  faCalendarAlt,
  faUndo,
  faBookmark,
  faMapMarkerAlt,
  faHandHoldingUsd,
  faPercent,
  faArrowCircleDown,
  faLongArrowAltRight,
  faCheckCircle,
  faPaperPlane,
  faPrint,
  faCircleNotch,
  faCalculator,
  faUserSlash,
  faTableCells,
  faTable,
  faEye,
  faJetFighter,
  faCheck,
  faPlus,
  faXmark,
  faBolt,
  faCircle,
  faCircleInfo,
  faTriangleExclamation,
  faBatteryQuarter,
  faBatteryHalf,
  faBatteryFull,
  faRoadBarrier,
  faWarning,
  faEnvelope,
  faPhone,
  faEllipsisH,
  faTimes,
  faList,
    faFileSignature
} from "@fortawesome/free-solid-svg-icons";

library.add(faHome,faBuilding,faPrint, faUser,faUserSlash,faUserPlus, faSignInAlt, faSignOutAlt,faSearch,faSearchPlus,faEuroSign,faPencilAlt,faUniversity,faCalendar,faStopwatch,faQuestionCircle,faHandshake,faThumbsUp,faBan,faMapMarker,faBarcode,faArrowRight,faCalendarAlt,faUndo,faBookmark,faMapMarkerAlt,faHandHoldingUsd,faPercent,faArrowCircleDown,faLongArrowAltRight,faCheckCircle,faPaperPlane,faCalendarCheck, faCircleNotch, faCalculator, faTableCells, faTable, faEye, faJetFighter,faCheck,faPlus,faXmark,faBolt, faCircle,faTriangleExclamation,faBatteryQuarter, faBatteryHalf, faBatteryFull, faRoadBarrier,faWarning,faCircleInfo, faEllipsisH, faTimes, faEnvelope, faPhone, faList, faFileSignature);

export { FontAwesomeIcon };



